* {
  font-family: "SuperMarioFont", sans-serif;
}
.coin {
  color: #ffd80e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lives {
  color: #5aff0e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.time {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  color: #0050c7;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0%;
}
.containerUI {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  padding: 10px;
  flex-direction: column;
}
.nav {
  display: flex;
  width: 100vw;
  max-height: 10vh;
  justify-content: space-between;
  padding: 10px;
  flex-direction: row;
}
.button-container {
  padding: 10px;
}
.logo {
  height: 15vh;
}
.controlButton {
  position: relative;
  display: flex;
  background: #0050c7;
  border-radius: 5px;
  border: 7px black solid;
  justify-content: center;
  align-items: center;
  color: #ffd80e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 10vh;
  width: 10vh;
  cursor: pointer;
}

.hover-content {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 5px;
  border-radius: 4px;
  height: 30vh;
  animation: showOnLoad 10s;
  opacity: 0;
}

.controlButton:hover .hover-content {
  opacity: 1;
}

@keyframes showOnLoad {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.image {
  height: 50vh;
}

.content {
  display: flex;
  border: 2px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.youWin {
  display: flex;
  flex-direction: column;
  background: #0050c7;
  border-radius: 5px;
  border: 7px black solid;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ffd80e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 30vh;
  width: 30vw;
  padding: 10rem;
}
.restartButton {
  background: rgba(244, 6, 6, 1);
  color: #ffd80e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  padding: 10px;
  border-radius: 5px;
  border: 7px black solid;
  justify-content: center;
}
