.click {
  color: #ffd80e;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin: 0;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.load_container {
  width: 50vw;
  display: flex;
}
.loader {
  min-height: 5vh;
  background-color: #0050c7;
  position: relative;
  display: flex;
  align-items: center;
}
.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  transition: opacity 8s;
  background-color: #4a045c;
}
.logo {
  height: 30vh;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.star {
  height: 6vh;
  position: absolute;
  right: -3vh;
  animation: rotation 1s linear infinite;
}
.onStarted {
  display: hidden;
  transition: opacity 8s;
  opacity: 0;
}
